export default [
  {
    title: 'Como puedo obtener una cotización',
    answer: `Puede solicitar una cotización a través de nuestros canales de contacto: Correo Electrónico, Whatsapp, Instagram y Formulario de Contacto.
    
    <p style="margin-top:1em"><small><em>Enlaces disponibles al final de esta página</em><small></p>

      `,
  },
  {
    title: 'Cual es el costo de una escalera y/o revestimiento',
    answer: `En el caso de una escalera para una vivienda particular depende de muchos factores pero principalmente de la materia prima (pino, pino oregón y/o madera nativa ) y de la complejidad de la escalera según el espacio disponible en la vivienda. En el caso de constructoras y/o arquitectos se deben enviar planos  para un análisis en detalle. En revestimientos depende del ancho de su escalera ya que todos nuestros revestimientos son de madera nativa para una mayor durabilidad.`,
  },
  {
    title: 'Cual es la materialidad de las escaleras',
    answer: `Somos una empresa especializada 100% en madera desde hace más de 25 años, por lo que la estructura, partes y piezas son íntegramente de este material.`,
  },
  {
    title: 'Cuanto demora el proyecto',
    answer:
      'Para viviendas particulares generalmente una vez abonado el trabajo de 4-6 semanas instalada. (4-5 fabricación, 1 de instalación). En el caso de proyectos inmobiliarios se realizan entregas parciales según vaya avanzando las obras.',
  },

  {
    title: 'Cual es el procedimiento si me decido a trabajar con uds',
    answer:
      'Aceptar el presupuesto y agendar una visita para rectificar medidas y definir tonalidades ya sea en el caso de una escalera o revestimiento, luego se debe abonar según corresponda para pasar a la fabricación.',
  },
  {
    title: 'Realizan otros trabajos que no sean en madera',
    answer: `No por el momento.`,
  },
];
