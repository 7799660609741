import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container, Grid, Art } from '@components/global';

const Intro = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="intro">
        <Container>
          <Grid>
            <div>
              <h2>Estilo</h2>
              <p>
                Nos preocupa que cada pieza y peldaño lleve el sello del
                profesionalismo y de la clase que sólo se puede lograr con amor
                por lo que hacemos.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Experiencia</h2>
              <p>
                28 años desarrollando proyectos nos permiten brindar la mejor
                combinación de calidad, belleza y precio en nuestras escaleras
                Rectas, en V, en U o Helicoidales
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Hacemos realidad tu proyecto</h2>
              <p>
                Comenzamos con una visita a terreno, nos cuentas lo que deseas,
                juntos encontramos la mejor solución, y luego: la construimos.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

export default Intro;
