import React from 'react';
import { SectionInternal, Container } from '@components/global';
import './form.scss';
import { useForm, ValidationError } from '@formspree/react';
import theme from '../../styles/theme';

export default function Contact() {
  return (
    <SectionInternal id="contacto">
      <Container theme={theme}>
        <div className="container">
          <h2>Contáctenos</h2>
          <ContactForm />
        </div>
      </Container>
    </SectionInternal>
  );
}

function ContactForm() {
  const [state, handleSubmit] = useForm('xknkgklg');
  const [contactoTelefono, setContactoTelefono] = React.useState(false);

  if (state.succeeded)
    return (
      <p style={{ fontSize: '20px' }}>
        Hemos recibido tu solicitud, nos pondremos en contacto a la brevedad.
      </p>
    );

  const onChange = e => {
    setContactoTelefono(e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-25">
          <label htmlFor="nombre">Nombre</label>
        </div>
        <div className="col-75">
          <input type="text" id="nombre" name="nombre" />
          <ValidationError
            prefix="Nombre"
            field="nombre"
            errors={state.errors}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-25">
          <label htmlFor="email">Email</label>
        </div>
        <div className="col-75">
          <input type="email" id="email" name="email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
      </div>
      <div className="row">
        <div className="col-25">
          <label htmlFor="asunto">Consulta</label>
        </div>
        <div className="col-75">
          <textarea
            id="asunto"
            name="asunto"
            style={{ height: '200px' }}
          ></textarea>
        </div>
      </div>
      <div className="row">
        <div className="col-25">
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={contactoTelefono}
              onChange={onChange}
            ></input>{' '}
            <span style={{ marginLeft: '0.5em' }}>
              Quiero que me contacten por teléfono:
            </span>
          </label>
        </div>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="Ingrese número de teléfono"
          disabled={!contactoTelefono}
        />
      </div>
      <div className="row" style={{ marginTop: '2em' }}>
        <button type="submit" disabled={state.submitting}>
          {state.submitting ? 'Enviando solicitud....' : 'Enviar'}
        </button>
      </div>
    </form>
  );
}
