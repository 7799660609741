import React from 'react';
import { SectionInternal, Container } from '@components/global';
import styled from 'styled-components';

const Parrafo = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
`;

export default function About() {
  return (
    <SectionInternal id="acerca_de">
      <Container>
        <h2>Acerca de</h2>
        <Parrafo>
          Pastene es una empresa familiar que se inicio el año 1994 con el fin
          de satisfacer requerimientos en el área de la construcción con
          componentes de madera ornamentales y estructurales de fina
          terminación, específicamente en el desarrollo de proyectos y de
          productos especiales a pedido.
        </Parrafo>

        <Parrafo>
          Pastene desarrolla productos de madera con requerimientos de mano de
          obra especializada a las que se le incorporan soluciones
          industrializadas para su fácil y rápida instalación en obra.
        </Parrafo>

        <Parrafo>
          Pastene tiene incorporada en todos sus productos la tecnología de
          laminación de maderas finger joint y su secado artificial (12%), para
          su óptimo aprovechamiento e insuperable estabilidad. Tenemos una gran
          variedad de maderas principalmente usamos: Raulí, Roble, Lenga y Pino
          Radiata.
        </Parrafo>
      </Container>
    </SectionInternal>
  );
}
