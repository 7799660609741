import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Servicios from '@sections/Servicios';
// import Brands from '@sections/Brands';
// import Team from '@sections/Team';
import Faq from '@sections/Faq';
import FooterAlt from '@sections/FooterAlt';
import Intro from '@sections/Intro';
import Contact from '@sections/Contact';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <Intro />
    <About />
    <Servicios />
    {/* <Brands />
    <Team />*/}
    <Faq />
    <Contact />
    <FooterAlt />
  </Layout>
);

export default IndexPage;
