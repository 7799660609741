import React from 'react';
import { Link } from 'gatsby';
import { SectionInternal, Container } from '@components/global';

import styled from 'styled-components';
import productos from '../../datos/Productos';

const Grilla = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Servicio = styled.div`
  padding: 1em;

  border: solid 1px #ccc;|
`;
const Titulo = styled.h3``;
const Bajada = styled.em`
  font-size: 1.25em;
  margin: 0.5em 0;
  display: block;
`;
const Descripcion = styled.p`
  font-size: 1.25em;
  line-height: 1.25em;
`;

const Imagen = styled.div`
  margin-bottom: 0.75em;
`;
// eslint-disable-next-line react/prop-types
const Producto = ({ producto: { slug, nombre, bajada, desc } }) => (
  <Servicio>
    <Imagen>
      <img src={`/images/servicios/${slug}.jpg`} />
    </Imagen>

    <Titulo>{nombre}</Titulo>
    <Bajada>{bajada}</Bajada>
    <Descripcion>{desc}</Descripcion>
    <StyledExternalLink to="selector-productos">
      Vea nuestro catálogo &nbsp;&#x2794;
    </StyledExternalLink>
  </Servicio>
);

export default function Servicios() {
  return (
    <SectionInternal id="servicios">
      <Container>
        <h2 style={{ marginBottom: '.75em' }}>Servicios</h2>
        <Grilla>
          {productos.map(p => (
            <Producto producto={p} key={p.id} />
          ))}
        </Grilla>
      </Container>
    </SectionInternal>
  );
}

const StyledExternalLink = styled(Link)`
  padding-top: 1.5em;
  display: block;
  color: #666;
  text-decoration: none;
  text-align: right;

  &:hover {
    color: white;
  }
`;
