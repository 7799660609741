import React from 'react';
import faqs from '../../datos/faqs';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';

const Faq = () => (
  <Section id="preguntas_frecuentes">
    <Container>
      <h2 style={{ marginBottom: 40 }}>Preguntas Frecuentes</h2>
      <div>
        {faqs.map(({ title, answer }) => (
          <FaqItem title={title} key={title}>
            <div dangerouslySetInnerHTML={{ __html: answer }}></div>
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
